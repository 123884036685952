
.year-section {
  margin-bottom: 10px;
  font-weight: bold;
}

.statistic-image {
  float: left; /* Float the image to the left */
  margin: 0 20px 20px 0;
  max-height: 320px;
}

.statistic-text {
  /* Clear the float to ensure text flows below the image */
  clear: left;
}


.important{
  color: brown;
  font-weight: bold;
  font-size: 1.5rem;
}