.button {
  appearance: none;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #e5c98e8f 0,
    rgb(190, 46, 6) 100%
  );

  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  height: 3rem;

  margin: 10px;

  line-height: 2;
  list-style: none;
  overflow: hidden;
  font-family: "Source Serif 4", serif;;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
}

.button:focus {
  box-shadow: #e0783c 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #e0603c 0 -3px 0 inset;
}

.button:hover {
  box-shadow: rgba(173, 83, 23, 0.923) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #cf5904 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button:active {
  box-shadow: #e05a3c 0 3px 7px inset;
  transform: translateY(2px);
}

.button.inactive {
  background-color: gray;
  cursor: not-allowed;
}

.donate {
  padding: 6px 10px 10px 12px;
}