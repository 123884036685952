.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.page-container-guide {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.guide-to-click {
  font-size: large;
  margin-top: 1rem;
  text-align: center;
  align-self: center;
}

.guide-to-click-the-card{
  margin-bottom: 10px;
}
