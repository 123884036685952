.general_question_card_info{
  margin-top: 2rem;
}
.question-card-info {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  background-color: #f2d4a4cf; 
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25); 
  padding: 1px; 
  border: 4mm ridge rgba(220, 107, 50, 0.774);
}

@media screen and (max-width: 600px) {
  .question-card-info {
    flex-direction: column;
  }
}
.card-image-reversed {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.date {
  font-weight: bold;
}

.question {
  font-family: "Charm", cursive;
  font-size: 2rem;
  color: rgb(165, 5, 5);
  margin-bottom: 1rem;
}
.answer{
  font-style: italic;
  font-weight: bold;
}

