.info-cards-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: 100%;
}
.half-info-cards {
  display: flex;
  flex-direction: column;

  align-items: center;
  width: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.meaning-affirmation {
  margin-bottom: 10px;
}


.donation-message {
  color: rgb(160, 11, 11);
  font-weight: bold;
  margin-left: 20px;
  margin: 20px;
}

@media screen and (max-width: 600px) {
  .meaning-affirmation {
    width: 50%;
  }
}

.personal-meaning {
  background-color: #f2d4a4cf;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
  padding: 1px;
  border: 4mm ridge rgba(220, 107, 50, 0.774);
  margin-bottom: 10px;
}

.loading-img {
  width: 10%;
  height: 10%;
}

.ai-response {
  color: brown;
}
