.modal-container {
  position: relative;
  z-index: 10;
}

.backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.507);
}
.fullscreen-scrollable {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

.centered-container {
  position: relative;
  display: flex;
  min-height: 100vh; /* Full height of the viewport */
  align-items: center; /* Align children vertically in the center */
  justify-content: center; /* Center children horizontally */
  padding: 2rem; /* All around padding */
  text-align: center; /* Center text */
}

.modal {
  width: 100%;
  max-width: 28rem;
  overflow: hidden;
  border-radius: 1rem; /* equivalent to rounded-2xl */
  background-color:bisque;
  padding: 1rem;
  text-align: left;
  border-radius: 1rem;
  vertical-align: middle;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-in-out;
  margin: auto;
  position: relative;
}

.modal-title {
  font-size: 1.125rem; /* equivalent to text-lg */
  font-weight: 500; /* equivalent to font-medium */
  line-height: 1.5rem; /* equivalent to leading-6 */
  color: #111827; /* equivalent to text-gray-900 */
}

.modal-body {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem; /* equivalent to mt-2 */
}

.modal-text {
  font-size: 1rem; /* equivalent to text-sm */
  color: #191a1b;
}
