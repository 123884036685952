.info-cards-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.half-info-cards {
  display: flex;
  flex-direction: column;

  align-items: center;
  width: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.meaning-affirmation {
  margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .meaning-affirmation {
    width: 50%;
  }
}
