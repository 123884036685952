.card-img-personal {
  float: left; /* Float the image to the left */
  margin-right: 20px;
  height: 150px;
}

.image-container {
  width: 100%;
  clear: both; /* Clear any floating elements inside the container */
  margin-bottom: 20px;
}

@media (min-width: 600px) {
  .card-img-personal {
    float: left; /* Float the image to the left */
    margin-bottom: 20px;
    height: 100px;
  }

  .image-container {
    margin-bottom: 50px;
  }
}
