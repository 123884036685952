.choose_questions_container {
    color: brown;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    width: 90%;
    padding-bottom: 2rem;
  }
  
  .choose_question {
    font-size: large;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  .choose_question:focus {
    box-shadow: #a8470b 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #905108 0 -3px 0 inset;
  }
  
  .choose_question:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #a84a08 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .choose_question:active {
    box-shadow: #923111 0 3px 7px inset;
    transform: translateY(2px);
  }
  