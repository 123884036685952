.one-card-reveal {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 80%;
}

.one-card-display {
  display: flex;
}
.meanings-of-card {
  display: flex;
  gap: 1px;
  flex-direction: column;
  align-self: center;
}

.meaning-title {
  font-weight: bold;
  width: 90%;
  margin-left: 8px;
}

.card-image {
  height: 40vmin;
  width: fit-content;
}

.card-image-reversed {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media screen and (max-width: 600px) {
  .one-card-reveal {
    width: 100%;
  }
  .one-card-display {
    flex-direction: column;
    width: 100%;
    align-self: center;
  }
  .card-image {
    height: auto;
    align-self: center;
    width: 90%;
  }
}
