body {
  font-size: 1.1rem;
}
.autobiography {
  padding: 1.5rem;
  background-color: #f5dfbccf;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
  border: 2mm inset rgba(217, 36, 4, 0.907);
  margin: 1rem;
}
.welcome-guide {
  text-align: start;
}

.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-header {
  color: brown;
}

h1 {
  color: rgba(136, 97, 13, 0.969);
  text-align: center;
}

h2 {
  color: rgba(136, 97, 13, 0.744);
  text-align: center;
}

.guide-links {
  margin-left: 2rem;
  margin-right: 2rem;
}

.love-img {
  margin-bottom: 2rem;
}

.card-img {
  height: inherit;
  border: 2px solid bisque;
  border-radius: 2rem;
  box-shadow: 10px 10px;
}

.card-img:hover {
  transform: scale(1.04);
}

.frame-option{
  border: 2px solid brown;
  border-radius: 2rem;
  background-color: #f5dfbccf;
  box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  margin-top: 1rem;
}