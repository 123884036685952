* {
  box-sizing: border-box;
}
body {
  font-family: "Source Serif 4", serif;
}

a {
  text-decoration: none;
  font-family: "Source Serif 4", serif;
}
