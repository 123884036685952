.auth_button {
  appearance: none;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #e5c98e8f 0,
    rgb(190, 46, 6) 100%
  );
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  height: 3rem;

  margin: 10px;

  line-height: 2;
  list-style: none;
  overflow: hidden;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
}

.auth_button:focus {
  box-shadow: #e0783c 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #e0603c 0 -3px 0 inset;
}

.auth_button:hover {
  box-shadow: rgba(173, 83, 23, 0.923) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #cf5904 0 -3px 0 inset;
  transform: translateY(-2px);
}

.auth_button:active {
  box-shadow: #e05a3c 0 3px 7px inset;
  transform: translateY(2px);
}

.auth_button.inactive {
  background-color: gray;
  cursor: not-allowed;
}

input[type="email"],
input[type="password"],
input[type="text"] {
  width: 100%;
  line-height: 20px;
  font-size: 1.2rem;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input[type="email"]:focus,
input[type="password"]:focus,
input[type="text"]:focus {
  outline: none; /* Remove the default outline */
  border-color: #ff9900aa; /* Change the border color on focus */
  box-shadow: 0 0 5px rgba(255, 183, 0, 0.5); /* Add a subtle box shadow on focus */
}

.checkbox-container {
  display: flex; /* Use flexbox */
  align-items: center; /* Vertically center the items */
}
/* Basic checkbox style */
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  font-size: 17px;
  line-height: 17px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
}
/* Define custom checkbox appearance using pseudo-elements */
input[type="checkbox"] {
}

/* Style for checkbox hover state */
input[type="checkbox"]:hover {
  background-color: #e6e6e6; /* Background color on hover */
  border: 1px solid #ccc; /* Border color on hover */
}

/* Remove default checkbox appearance */
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

/* Style the checkmark when the checkbox is checked */
input[type="checkbox"]:checked::before {
  content: "\2713"; /* Checkmark symbol */
}
.form-to-register {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.registered_container {
  width: 50%;
}

.form-to-login {
  display: flex;
  flex-direction: column;
  width: 100%;
}

