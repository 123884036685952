/* Header */
header {
  background-color: antiquewhite;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
}
.navbar-image{
  padding-left: 1rem;
  padding-top: 10px;
  max-height: 2rem;
}
.arrow-button {
  background-color: antiquewhite;
  color: brown;
  border: 0;
  font-size: 1rem;
}

/* Logo */
.site-title {
  display: inline-block;
  color: brown;
  font-size: 2rem;
  margin-left: 10px;
}
/* Nav menu */
.nav {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: antiquewhite;
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 0.5s ease-out; /* Safari prefix */
  transition: max-height 0.5s ease-out;
  padding-left: 2rem;
  font-weight: bold;
  font-size: larger;
  top: 50px; /* Ensure the menu stays at the top of the viewport */
  z-index: 1; /* Ensure the menu is above other elements */
}

.menu {
  color: brown;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  list-style: none;
}

.menu a {
  display: block;
  color: brown;
}

.menu li.active {
  background-color: rgb(225, 197, 181);
  min-height: 35px;
}

.menu a:hover {
  background-color: transparent;
  color: rgb(240, 149, 149);
}

/* Menu Icon */
.hamb {
  cursor: pointer;
  float: right;
  padding: 20px 5px;
} /* Style label tag */

.hamb-line {
  background: brown;
  display: block;
  height: 2px;
  position: relative;
  width: 24px;
} /* Style span tag */

.hamb-line::before,
.hamb-line::after {
  background: brown;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}
.hamb-line::before {
  top: 5px;
}
.hamb-line::after {
  top: -5px;
}

.side-menu {
  display: none;
}
/* Hide checkbox */
/* Toggle menu icon */
.side-menu:checked ~ nav {
  max-height: 100%;
}
.side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}
.side-menu:checked ~ .hamb .hamb-line::before {
  transform: rotate(-45deg);
  top: 0;
}
.side-menu:checked ~ .hamb .hamb-line::after {
  transform: rotate(45deg);
  top: 0;
}

.dropdown-menu {
  position: absolute;
  left: 1rem;
  font-size: 1rem;
  font-style: italic;
  font-weight: 600;
  width: fit-content;
  display: flex;
  flex-direction: column;
  background-color: blanchedalmond;
  justify-content: flex-start;
  gap: 0.3rem;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.55) 0px 5px 15px;
  padding: 10px;
}
.my-profile-img{
  width: 20px;
  height: 20px;
}
.google-translate{
  padding-left: 10px;
  height: 40px;
 }

 .goog-te-combo{
  height: 30px;
 }

@media (min-width: 821px) {
  .nav {
    background-color: antiquewhite;
    color: brown;
    max-height: none;
    min-height: 35px;
    top: 0;
    position: relative;
    float: right;
    width: fit-content;
    overflow: visible;
    font-size: 1.2rem;
    margin-right: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }

  .menu {
    padding: 5px;
    margin: 0;
    flex-direction: row;
  }

  .hamb {
    display: none;
  }

  .dropdown-menu {
    position: absolute;
    left: auto;
  }

  .google-translate{
    padding-left: 0;
    display: inline;
    float: right;
    margin-right: 5px;
    margin-top: 5px;
   }
}

