.guide-card {
  align-self: center;
}

.asked_question {
  font-family: "Charm", cursive;
  color: brown;
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 0;
}

.one-card-spread-guide {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.buttons_display {
  display: flex;
  flex-direction: column;
  width: 90%;
}
@media screen and (min-width: 600px) {
  .buttons_display {
    width: 40%;
  }
}

@media screen and (min-width: 600px) {
  .one-card-spread-guide {
    width: 50%;
  }
}
.buttons-organize {
  display: flex;
}

.donate-main {
  padding: 1rem;
}

.donate {
  width: fit-content;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #d6ae588f 0,
    rgb(147, 37, 7) 100%
  );
}

.mysticsense_recommendation {
  display: flex;
  flex-direction: column;
}

@keyframes shuffle1 {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(200px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(70px);
  }
}

@keyframes shuffle2 {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(200px);
  }
  75% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(60px);
  }
}

@keyframes shuffle3 {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-200px);
  }
  50% {
    transform: translateX(100px);
  }
  75% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(50px);
  }
}

@keyframes shuffle4 {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-50px);
  }
  50% {
    transform: translateX(-250px);
  }
  75% {
    transform: translateX(-130px);
  }
  100% {
    transform: translateX(40px);
  }
}

@keyframes shuffle5 {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-190px);
  }
  50% {
    transform: translateX(-250px);
  }
  75% {
    transform: translateX(-130px);
  }
  100% {
    transform: translateX(30px);
  }
}

@keyframes shuffle6 {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-90px);
  }
  50% {
    transform: translateX(-150px);
  }
  75% {
    transform: translateX(130px);
  }
  100% {
    transform: translateX(-20px);
  }
}

.cards-shuffling-container {
  position: relative;
  width: 350px;
  height: 280px;
}
@media screen and (min-width: 1200px) {
  .cards-shuffling-container {
    left: 200px;
  }
}
.tarot-card {
  display: inline-block;
  position: absolute;
  transform-origin: bottom center;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  cursor: pointer; /* Add cursor pointer for interaction */
}
.tarot-card img {
  width: 100%;
  height: 100%;
}

.card1 {
  left: 0px; /* Adjust the value to control the overlap amount */
  z-index: 1;
}

.card2 {
  left: 30px; /* Adjust the value to control the overlap amount */
  z-index: 2;
}
.card3 {
  left: 60px; /* Adjust the value to control the overlap amount */
  z-index: 3;
}
.card4 {
  left: 90px; /* Adjust the value to control the overlap amount */
  z-index: 4;
}
.card5 {
  left: 120px; /* Adjust the value to control the overlap amount */
  z-index: 5;
}
.card6 {
  left: 150px; /* Adjust the value to control the overlap amount */
  z-index: 6;
}

.card1.shuffle {
  animation: shuffle1 1s ease-out;
  animation-fill-mode: forwards;
}

.card2.shuffle {
  animation: shuffle2 1s ease-in-out;
  animation-fill-mode: backwards;
}

.card3.shuffle {
  animation: shuffle3 1s ease-in-out;
  animation-fill-mode: forwards;
}

.card4.shuffle {
  animation: shuffle4 1s ease-out;
  animation-fill-mode: forwards;
}

.card5.shuffle {
  animation: shuffle5 1s ease-in-out;
  animation-fill-mode: forwards;
}
.card6.shuffle {
  animation: shuffle6 1s ease-in-out;
  animation-fill-mode: both;
}

.card1.active {
  transform: scale(1.2); /* Increase the scale for the "pop-up" effect */
}
.card2.active {
  transform: scale(1.2); /* Increase the scale for the "pop-up" effect */
}
.card3.active {
  transform: scale(1.2); /* Increase the scale for the "pop-up" effect */
}
.card4.active {
  transform: scale(1.2); /* Increase the scale for the "pop-up" effect */
}
.card5.active {
  transform: scale(1.2); /* Increase the scale for the "pop-up" effect */
}
.card6.active {
  transform: scale(1.2); /* Increase the scale for the "pop-up" effect */
}

.card1:hover {
  transform: scale(1.2); /* Increase the scale for the "pop-up" effect */
}
.card2:hover {
  transform: scale(1.2); /* Increase the scale for the "pop-up" effect */
}
.card3:hover {
  transform: scale(1.2); /* Increase the scale for the "pop-up" effect */
}
.card4:hover {
  transform: scale(1.2); /* Increase the scale for the "pop-up" effect */
}

.card5:hover {
  transform: scale(1.2); /* Increase the scale for the "pop-up" effect */
}
.card6:hover {
  transform: scale(1.2); /* Increase the scale for the "pop-up" effect */
}
