.quiz-container {
  display: flex;
  flex-direction: column;
}

.quiz-container .button {
  align-self: center;
}

@media screen and (max-width: 600px) {
  .react-quiz-container {
    width: 80%;
  }
}

.react-quiz-container .questionWrapper img {
  width: 40%;
}

.react-quiz-container .number-of-selection {
  background: #837438;
}

.react-quiz-container .selection-tag.single {
  background: #ac6917;
}

.react-quiz-container .questionWrapper .btn {
  background-color: rgba(246, 232, 222, 0.923);
}

.react-quiz-container .questionWrapper .incorrect {
  background-color: red;
}
.react-quiz-container .questionWrapper .nextQuestionBtn {
  background-color: #c44314e0;
  color: white;
}

.react-quiz-container .startQuizWrapper .startQuizBtn {
  color: white;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #e58eb11d 0,
    #c44314e0 100%
  );
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  cursor: pointer;

  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
}

.react-quiz-container .startQuizWrapper .startQuizBtn:focus,
.nextQuestionBtn:focus {
  box-shadow: #e0783c 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #e0603c 0 -3px 0 inset;
}

.react-quiz-container .startQuizWrapper .startQuizBtn:hover,
.nextQuestionBtn:hover {
  box-shadow: rgba(173, 83, 23, 0.923) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #cf5904 0 -3px 0 inset;
  transform: translateY(-2px);
}

.react-quiz-container .startQuizWrapper .startQuizBtn:active,
.nextQuestionBtn:active {
  box-shadow: #e05a3c 0 3px 7px inset;
  transform: translateY(2px);
}
