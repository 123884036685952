.container {
  background-color: antiquewhite;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  max-height: 2050vh;
  width: 100%;
}
h1 {
  color: brown;
}
.guide {
  margin: 1rem 1rem;
  align-self: center;
  width: 50%;
}

@media screen and (max-width: 600px) {
  .guide {
    width: 90%;
  }
}

li {
  font-style: italic;
  font-weight: bold;
  padding-bottom: 0.5rem;
}

ul {
  padding-inline-start: 15px;
}
a {
  color: brown;
}
.button-share {
  display: inline;
  margin-right: 10px;
  color: rgb(255, 255, 255);
  background-color: rgb(212, 107, 8);
  border: 1px solid rgb(199, 141, 79);
  border-radius: 25px;
  font: 1em sans-serif;
  height: 1.8rem;
  padding: 0px 10px 10px 10px;
  margin-bottom: 10px;
}
.copy-right {
  display: inline;
}
footer {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  border-top: 2px solid rgb(183, 100, 17);
}

.social-share{
  display: inline;
}

.svg-inline--fa{
  height: 1.5rem;
}

@media (max-width: 600px) {
  .copy-right {
    display: block;
  }
}

.alert{
  background-color: red;
  color: white;
}