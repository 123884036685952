.general-learn {
  margin: 30px;
  align-self: center;
}
.guide {
  margin-top: 1rem;
  margin-bottom: 0;
}
.cards-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  margin-bottom: 40px;
}

.cards-container-suite {
  display: grid;
  grid-template: auto auto / repeat(4, 1fr);
  gap: 10px;
}

@media screen and (max-width: 600px) {
  .cards-container-suite {
    display: grid;
    grid-template: auto auto / repeat(3, 1fr);
    gap: 10px;
  }
}

.custom-trigger {
  display: flex;
  justify-content: space-between;
  color: brown;
  font-size: 1.3rem;
  background-color: rgb(248, 210, 171);
  box-shadow: rgba(0, 0, 0, 0.55) 0px 10px 35px;
}

.Collapsible__trigger.is-open svg {
  transform: rotate(180deg);
}
