.label-for-text-field {
  display: block;
}

.text-field {
  font-family: "Charm", cursive;
  display: block;
  font: 1.2em sans-serif;
  width: 20rem;
  height: 5rem;
}

.textarea-container{
  border: 4mm ridge rgba(220, 107, 50, 0.774);
}

.questions-guide{
  border: 4mm ridge rgba(220, 107, 50, 0.774);
  padding: 8px;
}
.how-to-ask-questions{
  list-style-type: none;
}
.important_knowledge{
  margin-left: 0.5rem;
  color: brown;
}