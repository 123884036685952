.card-button {
  display: inline;
  cursor: pointer;
  width: fit-content;
}

.card-button:focus {
  box-shadow: #e49e11 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #e49e11 0 -3px 0 inset;
}

.card-button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #e49e11 0 -3px 0 inset;
  transform: translateY(-2px);
}

.card-button:active {
  box-shadow: #e0653c 0 3px 7px inset;
  transform: translateY(2px);
}

